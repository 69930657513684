import logo from './logo.svg';
import './App.css';
import Error from './pages/Error';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path="error" element={<Error />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
